var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer"},[_c('div',{staticClass:"top"}),_c('div',{staticClass:"content"},[_c('el-table',{attrs:{"data":_vm.tableData,"tooltip-effect":"dark","header-cell-style":{ 'text-align': 'center' },"cell-style":{ 'text-align': 'center' }}},[_c('el-table-column',{attrs:{"prop":"clubLogo","label":"俱乐部LOGO"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":row.clubLogo,"alt":""}})])]}}])}),_c('el-table-column',{attrs:{"prop":"clubName","label":"俱乐部名称"}}),_c('el-table-column',{attrs:{"prop":"responsiblePerson","label":"负责人名称"}}),_c('el-table-column',{attrs:{"prop":"responsiblePersonPhone","label":"负责人电话"}}),_c('el-table-column',{attrs:{"prop":"modifyStatus","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('p',{style:({
              color:
                row.modifyStatus === 0
                  ? '#333333'
                  : row.modifyStatus === 1
                  ? '#5E83F5'
                  : '#EE4242',
            })},[_vm._v(" "+_vm._s(row.modifyStatus_dictText)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(Number(scope.row.modifyStatus) === 0)?_c('el-popconfirm',{attrs:{"title":"确定通过俱乐部信息变更审核吗？","popper-class":"customerdel-popover"},on:{"confirm":function($event){return _vm.cancelOrder(scope.row, 1)}}},[_c('el-button',{staticClass:"textblue-btn",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("同意")])],1):_vm._e(),(Number(scope.row.modifyStatus) === 0)?_c('el-popconfirm',{attrs:{"title":"确定拒绝俱乐部信息变更审核吗？","popper-class":"customerdel-popover"},on:{"confirm":function($event){return _vm.cancelOrder(scope.row, 2)}}},[_c('el-button',{staticClass:"textred-btn",attrs:{"slot":"reference"},slot:"reference"},[_vm._v("拒绝")])],1):_vm._e(),_c('el-button',{staticClass:"text-btn",attrs:{"type":"text"},on:{"click":function($event){return _vm.checkDetail(scope.row)}}},[_vm._v("变更详情")])]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > _vm.queryParams.pageSize),expression:"total > queryParams.pageSize"}],attrs:{"total":_vm.total,"page":_vm.queryParams.pageNum,"limit":_vm.queryParams.pageSize},on:{"update:page":function($event){return _vm.$set(_vm.queryParams, "pageNum", $event)},"update:limit":function($event){return _vm.$set(_vm.queryParams, "pageSize", $event)},"pagination":_vm.init}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }